@import 'components/Styles.scss';

.map-filter {
    &-content {
        display: flex;
        align-items: center;
        margin-left: 12px;
        @include phone {
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            height: 100vh;
            width: 100vw;
            background: $white;
            margin-left: 0;
        }

        @include tablet {
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            height: 100vh;
            width: 100vw;
            background: $white;
            margin-left: 0;
        }

        @include phone-landscape {
            position: fixed;
            align-items: stretch;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            height: 100vh;
            width: 100vw;
            background: $white;
            margin-left: 0;
        }
    }
    &-head {
        display: none;
        @include phone {
            position: relative;
            display: block;
            height: 20px;
            margin: 4px 0px 12px 0px;
            padding: 14px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include tablet {
            position: relative;
            display: block;
            height: 20px;
            margin: 4px 0px 12px 0px;
            padding: 14px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        @include phone-landscape {
            position: relative;
            display: block;
            height: 20px;
            margin: 4px 0px 12px 0px;
            padding: 14px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        h2 {
            color: $gray2;
            margin: 0;
            font-size: 15px;
            line-height: 20px;
            font-weight: 400;
            text-align: center;
        }
    }
}

.map-filter-buttons-container {
    @include phone-landscape {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
    }
}
