@import 'components/Styles';

.office-list-container {
    height: 100%;
    overflow-y: scroll;
    pointer-events: all;

    @include phone {
        padding-top: 10px;
        overflow-y: hidden;
    }
}