@import 'components/Styles';

.drawer-inner-content {
    width: 100%;
    display: flex;
    pointer-events: all;
    overflow-x: hidden;
    flex-direction: column;
    background: $white;
    border-top-right-radius: 20px;
    box-shadow: $box-shadow-5;
    padding: 30px 0px 0px 0px;
}

.select-office {
    padding: 0 20px 20px;
    box-shadow: $box-shadow-2;
    @include phone {
        position: relative;
        z-index: 1;
        padding-bottom: 10px;
    }

    @include tablet {
        position: relative;
        z-index: 1;
        padding-bottom: 10px;
    }

    #drawer-header {
        white-space: break-spaces;
        flex-wrap: wrap;
        gap: 10px;

        @media only screen and (max-width: 700px) {
            img {
                margin: auto;
            }
        }
    }
    

}

.location-button {
    cursor: pointer;
    pointer-events: all;
}
