$textColor: #454559;
$textLight: #6B6A7A;
$textTitle: #2F2E43;
$logoTextColor: #323246;
$accentColor: #B52479;

$blue1: #003F85;
$blue2: #4877CE;
$blue3: #BAD0FF;
$blue4: rgba($blue3, 0.4);
$blue5: rgba($blue3, 0.2);

$gray1: #212121;
$gray2: #424242;
$gray3: #616161;
$gray4: #999999;
$gray5: #C4C4C4;
$gray6: #D7D7D7;
$gray7: #EBEBEB;
$gray8: #F0F0F0;
$gray9: #F5F5F5;

$white: #fff;
$black: #000;
$blackTransparent: #21212166;
$green: #339900;
$orange: #FC7E11;
$red: #E41E1A;

$box-shadow-1: 0px -4px 10px rgba($gray1, .1);
$box-shadow-2: 0px 5px 6px rgba($gray1, .1);
$box-shadow-3: 0px -3px 6px rgba($gray1, .1);
$box-shadow-4: 0px 4px 12px rgba($gray1, .15);
$box-shadow-5: 0px -4px 20px rgba($gray1, .2);
$box-shadow-6: 0px 4px 12px #21212126;

$space: 4px;
