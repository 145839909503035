@import 'components/Styles.scss';

.root {
    position: fixed;
    width: 100%;
    height: 100%;
}

.content-container {
    height: 100%;

    @include phone {
    }

    @include tablet {
    }

    @include desktop {
    }
}