@import 'components/Variables.scss';
@import 'components/Styles.scss';

.map-filter-button {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    height: 35px;
    border: 0px;
    padding-right: 13px;
    padding-left: 13px;
    border-radius: 25px;
    font-size: 15px;
    margin-right: 8px;

    @include phone {
        padding-right: 20px;
        padding-left: 20px;
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            bottom: auto;
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            margin: 0 auto;
            background: $gray7;
            opacity: 1;
        }
    }

    @include tablet {
        padding-right: 20px;
        padding-left: 20px;
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            bottom: auto;
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            margin: 0 auto;
            background: $gray7;
            opacity: 1;
        }
    }

    @include phone-landscape {
        padding-right: 20px;
        padding-left: 20px;
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            bottom: auto;
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            margin: 0 auto;
            background: $gray7;
            opacity: 1;
        }
    }

    @include phone {
        display: block;
        width: 100%;
        height: 64px;
        border-radius: 0;
        text-align: left;
    }

    @include tablet {
        display: block;
        width: 100%;
        height: 64px;
        border-radius: 0;
        text-align: left;
    }

    @include phone-landscape {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 64px;
        border-radius: 0;
        text-align: left;
    }
    img {
        display: inline-flex;
        height: 17px;
        max-width: 17px;
        margin-right: 10px;
        @include phone {
            height: 36px;
            margin-right: 20px;
        }
        @include tablet {
            height: 36px;
            margin-right: 20px;
        }
    }
    .k-button-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &.active {
        background: $gray3;
        color: $white;
        &::after {
            display: none;
        }
    }
    &.disable {
        background: none;
        color: $black;
    }
    &.disable:hover {
        background: $gray9;
        color: $black;
    }
    &:focus, 
    &.k-focus {
        box-shadow: none;
    }
}

.map-filter-button:last-child {
    margin-right: 2px;
}