@import 'components/Styles';
@import 'components/Variables';

.office-export-button {
    display: block;
    margin: 16px auto 12px;

    @include phone {
        margin: 8px auto 8px;
    }

    @include phone-landscape {
        margin: 8px auto 8px;
    }

    @media only screen and (max-width: 700px) {
        width: 100%;
        min-width: auto;
        font-size: 15px;
        white-space: break-spaces;
        height: 100%;
    }
}

.office-address-head {
    cursor: pointer;
}

.office {
    &-address {
        &-item {
            position: relative;
            padding: 12px 20px;

            @include phone-landscape {
                padding-left: 12px;
            }

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                display: block;
                width: calc(100% - 30px);
                height: 1px;
                margin: 0 auto;
                background: $gray9;
            }

            .marker {
                display: block;
                width: 24px;
                margin-top: 2px;
            }

            >div:not(.office-address-head) {
                display: flex;
                align-items: flex-start;
                margin-top: 16px;

                .image {
                    margin-right: 20px;
                }

                @media only screen and (max-width: 400px) {
                    flex-direction: row;
                    align-items: flex-start;
    
                    .office-work-hours-content {
                        margin: 0;
                        text-align: center;

                        >div.office-work-status {
                            text-align: left;
                        }

                        .office-work-hours-item {
                            div {
                                text-align: start;
                            }
                        }
                    }

                    >a.link {
                        text-align: center;
                    }

                    .image {
                        margin-right: 10px;
                    }
                }
            }

            .link {
                color: $blue1;
                line-height: 24px;
                text-decoration: none;
            }

            &.visited {
                background-color: rgba($blue3, .20);
            }

            &:not(.select):hover {
                background-color: $gray9;

                &::after {
                    display: none;
                }
            }

            &.select {
                .office-address-head {
                    padding-bottom: 10px;
                    border-bottom: 1px solid $gray9;
                }
            }
        }

        &-head {
            display: flex;
            align-items: flex-start;

            strong {
                display: block;
            }
        }

        &-name {
            margin-left: 20px;
            //margin-right: 20px;
            font-size: 15px;
        }

        &-distance {
            margin-left: auto;
            color: $blue1;
            font-size: 15px;
            font-weight: bold;
        }

        &-action-btn {
            justify-content: space-between;
            margin: 10px -10px 0;

            @media only screen and (max-width: 400px) {
                gap: 20px;
                flex-direction: column;
                align-items: center;

                button {
                    min-width: auto;
                    width: 100%;
                    min-height: auto;
                    height: 100%;

                    > span {
                        flex-direction: column;
                    }
                }

                .btn .k-button-text img {
                    margin-right: 0;
                }
            }
        }
    }

    &-work {
        &-hours {
            >div {
                width: 100%;
                font-size: 15px;
            }

            &-item {
                >div span {
                    float: right;
                }
            }
        }

        &-status {
            font-weight: bold;

            &.open {
                color: $green;
            }

            &.close {
                color: $red;
            }
        }
    }
}