@import 'components/Styles';

.input-container {
    display: flex;
    flex-direction: column;
    pointer-events: all;

    @include phone {
        padding-bottom: 15px;
    }
}

.k-autocomplete {
    height: 50px;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px; 
    margin-left: 0px;
    border-radius: 5px;
    border: 1px solid $gray5;
    &:hover {
        border-color: $gray4;
    }
    &:focus, 
    &.k-focus {
        border-color: $blue2;
    }
    + .k-autocomplete {
        margin-top: 20px;
    }
   input::placeholder {
        font-weight: 300;
        font-style: italic;
        opacity: 1;
        color: $gray4;

        @include desktop {
            opacity: 0;
        }
    }
}
.k-clear-value {
    padding-right: 8px;;
}
.k-floating-label-container {
    width: 100%;
    padding-top: 0;
    + .k-floating-label-container {
        margin-top: 20px;
    }
    input {
        position: relative;
        @include desktop {
            height: 62px;
            &.k-input-inner {
                padding: 0px 0px 0px 16px;
            }
        }    
    }
    &.k-state-empty:not(.k-state-focused) ::placeholder, 
    &.k-empty:not(.k-focus):not(:focus-within) ::placeholder {
        color: $gray4;
    }
    > .k-label,
    &.k-state-empty > .k-label, 
    &.k-empty > .k-label,
    &:focus-within .k-label {
        display: none;
        top: 5px;
        left: 16px;
        font-size: 15px;
        font-weight: 300;
        font-style: italic;

        @include desktop {
            display: block;
        }
    }
    &.k-empty > .k-label {
        top: 15px;
    }
    &.k-focus.k-empty > .k-label {
        top: 4px;
    }
    &.k-focus .k-input-solid {
        border-color: $blue2;
        box-shadow: none;
    }
    > .k-label,
    &:focus-within .k-label {
        font-style: normal;
        font-size: 13px;
    }
}

.k-list {
    border: 1px solid $gray5;
    border-radius: 5px;
    &-item {
        display: block;
        width: 100%;
        padding: 8px 16px !important;
        border-bottom: 1px solid $gray7;
        font-size: 15px;
        color: $gray1;
        &:focus, 
        &.k-focus {
            box-shadow: none;
        }
        &:hover,
        .k-hover {
            background-color: $gray9;
        } 
        &:active,
        .k-selected {
            background-color: $blue5;
            color: $gray1;
        }
    }
}

.k-list-item {
    padding: 0px !important;
    border-width: 1px;
    &.k-focus {
        background-color: $blue5;
    }
}

.search-item {
    padding: 8px 16px !important;
}

.address-container {
    margin-top: 20px;
}