@import 'components/Styles.scss';
@import 'components/Variables.scss';

.office-popup {
    background-color: white;
    overflow: visible;

    @media only screen and (min-width: 768px) {
      overflow: scroll;
    }

    @include phone {
        padding: 10px 0px;
        flex-grow: 1;
    }

    @include phone-landscape {
        width: 350px;
    }
}

.office-popup-back-button {
    margin: 10px 0px 10px 0px;
    display: flex;
    color: $blue1;

    @include phone {
        margin: 12px 0px 12px 24px;
    }

    @include phone-landscape {
        margin: 12px 0px 12px 16px;
    }
}

.office-popup-back-button-text {
    height: 24px;
    line-height: 24px;
    display: inline;
    color: $blue1;
}

.office-popup-back-butto-image {
    width: 16px;
    height: 16px;
    margin: 4px 12px 4px 0px;
}
