@import 'components/Variables';
@import 'components/Fonts.scss';

@mixin phone {
    @media only screen and (max-width: 425px) { @content }
};

@mixin phone-landscape {
    @media only screen and (max-height: 425px) { @content }
};

@mixin tablet {
    @media only screen and (min-width: 426px) and (max-width: 768px) { @content }
};

@mixin laptop {
    @media only screen and (min-width: 769px) and (max-width: 1024px) { @content }
};

@mixin desktop {
    @media only screen and (min-width: 1440px) { @content }
};

@mixin center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin fade-in-animation {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

@mixin title {
    font-family: 'Nimbus';
    font-size: 1.5em;
    color: variables.$textTitle;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.btn {
    height: 50px;
    min-width: 170px;
    padding: 12px 28px; 
    border-radius: 25px;
    border: 1px solid transparent;
    background: none;
    font-size: 18px;
    line-height: 24px; 
    .k-button-text {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: inline-flex;
            margin-right: 15px;
        }
        strong {
            margin-right: 3px;
        }
    }
    &:hover {
        background: none;
    }
    &-sm {
        min-width: 162px;
        height: 32px;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 15px;
    }
    &-icon {
        min-width: 40px;
        border: 0;
        border-radius: 0;
    }
    &-primary-solid {
        border-color: $blue1;
        background: $blue1;
        color: $white;
        
        &:active,
        &:hover:not(:focus) {
            border-color: $blue2;
            background: $blue2;
        }
        &:focus,
        &.k-focus {
            border-color: $blue1;
            background: $blue1;
            box-shadow: 0 0 0 2px rgba($blue2, .25);
        }
        &:disabled {
            border-color: $blue3;
            background: $blue3;
        }
    }
    &-primary-outline {
        border-color: $blue1;
        background: $white;
        color: $blue1;
        
        &:active,
        &:hover:not(:focus) {
            border-color: $blue1;
            background: $blue1;
            color: $white;
        }
        &:focus,
        &.k-focus {
            border-color: $blue1;
            background: $white;
            box-shadow: 0 0 0 2px rgba($blue2, .25);
            color: $blue1;
        }
        &:disabled {
            border-color: $blue3;
            background: $white;
            color: $blue3;
        }
    }
    &-primary-flat {
        border-color: transparent;
        background: none;
        color: $blue1;
        &:focus,
        &.k-focus {
            border-color: transparent;
            background: $white;
            box-shadow: 0 0 0 2px rgba($blue2, .25);
            color: $blue1;
        }
        &:hover:not(:focus) {
            border-color: transparent;
            background: $blue5;
            color: $blue1;
        }
        &.active,
        &:active {
            border-color: transparent;
            background: $blue4;
            color: $blue1;
        }
        &.active.k-focus {
            border-color: transparent;
            background: $blue4;
            color: $blue1;
            box-shadow: 0 0 0 2px rgba($blue2, .25);
        }
        
        &:disabled {
            border-color: transparent;
            background: none;
            color: $blue3;
        }
    }
    &-secondary-solid {
        border-color: $gray6;
        background: $gray6;
        color: $gray3;
        
        &:active,
        &:hover:not(:focus) {
            border-color: $gray4;
            background: $gray4;
            color: $white;
        }
        &:focus {
            border-color: $gray6;
            background: $gray6;
            box-shadow: 0 0 0 2px rgba($gray6, .25);
        }
        &:disabled {
            border-color: $gray6;
            background: $gray6;
            color: $gray5;
        }
    } 
    &-secondary-outline {
        border-color: $gray6;
        background: $white;
        color: $gray3;
        
        &:active,
        &:hover:not(:focus) {
            border-color: $gray6;
            background: $gray6;
            color: $gray3;
        }
        &:focus {
            border-color: $gray6;
            background: $white;
            box-shadow: 0 0 0 2px rgba($gray6, .25);
            color: $gray3;
        }
        &:disabled {
            border-color: $gray7;
            background: $white;
            color: $gray6;
        }
    } 
}
