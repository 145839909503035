@import 'components/Styles.scss';
@import 'components/Variables.scss';

.main-container {
    position: relative;
    height: 100%;
}

.map-office {
    display: flex;
    flex-direction: column;

    @include phone-landscape {
        flex-direction: row;
    }
}

.map-container {
    height: 100%;

    &.compressed {
        @include phone {
            flex-shrink: 1;
        }

        @include tablet {
            flex-shrink: 1;
        }

        @include phone-landscape {
            flex-grow: 1;
        }

    }
}

.loading-container {
    position: absolute;
    z-index: 3;
    background-color: $blackTransparent;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.drawer-container {
    pointer-events: none;
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: space-between;
}

.popup-container {
    @include center-content;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: $blackTransparent;
}

.zoom-container {
    position: absolute;
    left: 96%;
    top: 88%;
    right: 0;
    bottom: 0;

    @include phone {
        display: none;
    }

    @include phone-landscape {
        display: none;
    }

    @include tablet {
        left: 92%;
        top: 5%;
        right: 0;
        bottom: 0;
    }

    @include laptop {
        left: 95%;
        top: 88%;
        right: 0;
        bottom: 0;
    }
}

.edl-drawer.collapsed {
    transform: translateX(-86%);
}

.edl-drawer-content-container {
    margin-top: 1rem;
}

@media only screen and (min-width: 700px) {
    .edl-drawer.expanded {
        min-width: 415px;
    }
}

@media only screen and (max-width: 699px) {
    .edl-drawer.expanded {
        width: 100%;
        min-width: 152px;
    }
}