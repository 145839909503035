@import 'components/Styles.scss';

.map-filter {
    display: flex;
    width: fit-content;
    margin: 20px 20px 20px 20px;
    padding: 4px;
    border-radius: 30px;
    pointer-events: all;
    height: fit-content;

    &.small {
        border-radius: 22px 0px 22px 0px;
    }

    @include tablet {
        position: fixed;
        left: 0;
        top: 0;
    }

    @include phone {
        position: fixed;
        left: 0;
        top: 0;
    }

    @include phone-landscape {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1;
    }

    &.open {
        background-color: $white;
        box-shadow: $box-shadow-4;
        @include phone { 
            position: relative;
            z-index: 4;
            width: 100%;
            height: 100%;
            .map-filter-open-button {
                position: fixed;
                top: 10px;
                right: 16px;
                padding: 0;
                height: auto;
                border: 0;
                background: none;
                z-index: 1;
                .filter-icon {
                    display: none;
                }
                .close-icon {
                    display: block;
                }
            }
        }

        @include tablet {
            position: relative;
            z-index: 4;
            width: 100%;
            height: 100%;
            .map-filter-open-button {
                position: fixed;
                top: 10px;
                right: 16px;
                padding: 0;
                height: auto;
                border: 0;
                background: none;
                z-index: 1;
                .filter-icon {
                    display: none;
                }
                .close-icon {
                    display: block;
                }
            }
        }

        @include phone-landscape { 
            position: relative;
            z-index: 4;
            width: 100%;
            height: 100%;
            .map-filter-open-button {
                position: fixed;
                top: 10px;
                right: 10px;
                padding: 0;
                height: auto;
                border: 0;
                background: none;
                z-index: 1;
                .filter-icon {
                    display: none;
                }
                .close-icon {
                    display: block;
                }
            }
        }
    }
        &-open-button {
        width: 46px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 20px;
        background-color: $white;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        display: flex;
        &:focus,
        .close-icon {
            display: none;
        }
        &.closed {
            &:hover {
                background-color: $gray9;
            }
            box-shadow: $box-shadow-6;
        }
        &:hover{
            background-color: $gray6;
        }
    }
}
